<template>
  <svg
    viewBox="0 0 20 20">
    <path
      d="M15.71,7.33 C15.64,11.95 12.69,15.11 8.28,15.31 C6.46,15.39 5.15,14.81 4,14.08 C5.34,14.29
      7,13.76 7.9,13 C6.58,12.86 5.81,12.19 5.44,11.12 C5.82,11.18 6.22,11.16 6.58,11.09
      C5.39,10.69 4.54,9.95 4.5,8.41 C4.83,8.57 5.18,8.71 5.64,8.74 C4.75,8.23 4.1,6.38 4.85,5.16
      C6.17,6.61 7.76,7.79 10.37,7.95 C9.71,5.15 13.42,3.63 14.97,5.5 C15.63,5.38 16.16,5.14
      16.68,4.86 C16.47,5.5 16.06,5.97 15.56,6.33 C16.1,6.26 16.59,6.13 17,5.92 C16.75,6.45
      16.19,6.93 15.71,7.33 L15.71,7.33 Z M18,0 L2,0 C0.8954305,0 0,0.8954305 0,2 L0,18
      C0,19.1045695 0.8954305,20 2,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,2 C20,0.89
      19.1,0 18,0 L18,0 Z">
    </path>
  </svg>
</template>
